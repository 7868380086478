@font-face {
  font-family: 'TT_FORS_TRIAL_REGULAR';
  src: url('./static/fonts/TT_FORS_TRIAL_REGULAR.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TT_FORS_TRIAL_MEDIUM';
  src: url('./static/fonts/TT_FORS_TRIAL_MEDIUM.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT_FORS_TRIAL_BOLD';
  src: url('./static/fonts/TT_FORS_TRIAL_BOLD.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}