:root {
    --primary-color: #29488d;
    --secondary-color: #f4f4f4;
    --text-color: #01091C;
    --light-color: #fff;
    --gray-color: #f4f7f8;
    --gray-color2: #e6ebec;
    --danger-color: red;
    --success-color: #00C672;
    --warning-color: #FF8900;
    --space-0: 0;
    --space-1: 5px;
    --space-2: 24px;
    --space-3: 30px;
    --space-4: 60px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'TT_FORS_TRIAL_REGULAR', sans-serif;
}

.body {
    min-height: 60vh;
    background-color: #FAF9F7;
}

.main {
    background-color: #FAF9F7;

    min-height: 70vh;
    padding-top: 0;
}

#map {
    height: 180px;
}

.container {
    max-width: 1206px !important;

}

/* header {
    background-color: var(--light-color);
} */

/* TEXT STYLES */
.text {
    font-size: 16px;
    line-height: normal;
}

.title {
    position: absolute;
    top: 20px;
    right: 0;
}

.text-title {
    font-size: 30px;
    line-height: 45px;
    margin: var(--space-2) 0;
    color: var(--text-color);
    font-weight: bold;
}

.text-medium {
    font-family: "TT_FORS_TRIAL_MEDIUM", sans-serif !important;
    font-size: 13px;
    font-weight: bold;
    line-height: 27px;
    color: var(--text-color);

}

.text-xs {
    font-size: 12px;
    color: #acacac;
    line-height: 18px;
}

.text-sm {
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
}

.text-md {
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: var(--text-color);
    text-align: center;
}

.text-lg {
    font-size: 22px;
    color: var(--text-color);
    line-height: 33px;
}

.text-xl {
    font-size: 30px;
    line-height: 45px;
    font-weight: bold;
    color: var(--text-color);
}

.text-through {
    text-decoration: line-through;
}


/* BUTTONS */

.small-span-button {
    font-size: 12px;
    color: var(--light-color);
    padding: 7px 15px;

    font-weight: 500;
    margin-right: var(--space-1);
}


.new {
    background-color: var(--success-color);
}

.discount {
    background-color: var(--warning-color);

}

.trend {
    background-color: var(--primary-color);
}

.sale {
    background-color: var(--warning-color);
    color: black;
}

.show-all-button {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    padding: var(--space-2) 0;
    transition: 0.37s;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    border-radius: var(--space-2);
    color: var(--primary-color);
    width: 169px;
}

.show-all-button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
}

/* FONT WEIGHT */

.bold {
    font-weight: 700;
}

.medium {
    font-weight: 500;
}

/* RADIUS */
.radius {
    border-radius: var(--space-2);
}

.link-div {
    display: flex;
    align-items: center;
    padding-top: 32px;
}

/* 
.p-1 {
    padding: var(--space-1);
}

.p-2 {
    padding: var(--space-2);
}

.p-3 {
    padding: var(--space-3);
}

.p-4 {
    padding: var(--space-4);
}

.px-1 {
    padding: 0 var(--space-1);
}

.px-2 {
    padding: 0 var(--space-2);
}

.px-3 {
    padding: 0 var(--space-3);
}

.px-4 {
    padding: 0 var(--space-4);
}

.py-1 {
    padding: var(--space-1) 0;
}

.py-2 {
    padding: var(--space-2) 0;
}

.py-3 {
    padding: var(--space-3) 0;
}

.py-4 {
    padding: var(--space-4) 0;
}



.pt-1 {
    padding-top: var(--space-1);
}

.pt-2 {
    padding-top: var(--space-2);
}

.mt-2 {
    margin-top: var(--space-2);
}

.mt-3 {
    margin-top: var(--space-3);
}

.mx-1 {
    margin: 0 var(--space-1);
}

.ml-1 {
    margin-left: var(--space-1);
}

.ml-2 {
    margin-left: var(--space-2);
}

.ml-3 {
    margin-left: var(--space-3);
} */

.info {
    position: relative;
    display: flex;
    /* align-items: center; */
    gap: 24px;
    height: 12vh;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: center;
}

.pagination li:first-child,
.pagination li:last-child,
.page-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li:first-child {
    margin-right: 2vw;
}

.pagination li:last-child {
    margin-left: 2vw;
}

.page-item {
    width: 30px;
    height: 30px;
    color: black;
    margin-left: .5vw;
}

.active {
    border: solid 1px;
}

.disabled {
    cursor: not-allowed !important;
    opacity: .6;
}

ul li:first-child a div {
    display: flex;
    align-items: center;
    gap: var(--space-1);
}

.btn {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    width: 100%;
    cursor: pointer;
    border-radius: var(--space-2);
}

.btn-primary {
    background-color: var(--primary-color) !important;
    color: var(--light-color) !important;
}

.btn-outline {
    background-color: rgba(41, 72, 141, .15) !important;
    color: var(--primary-color) !important;
}

.base-input {
    /* border: none; */
    /* background-color: var(--gray-color2); */
    border: 0.5px solid #01091C;
    padding: 13px;
    font-size: 16px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.base-input:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    outline: none;
}

.flex-column {
    flex-direction: column;
}

/* .flex {
    display: flex;
} */
.form-item {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 32px 0;
}

.form-item div {
    width: 40%;
}

.width100 {
    width: 100% !important;
}

.width50 {
    width: 50% !important;
}

.small-gallery .second-swiper-container {
    display: flex;
    gap: 16px;
}

/* .second-swiper-container {
    width: 100%;
    height: 100%;
} */

/* .small-image {
   height: 10vh;
} */



.main-params {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-direction: column;
}

.single-param {
    display: flex;
    justify-content: space-between;
}

.single-param h3 {
    width: 50%;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--space-2);
}

.grid-item {
    text-align: center;
}

.grid-item img {
    max-height: 80%;
    max-width: 80%;
}

.grid-image {
    background-color: var(--gray-color);
    border-radius: var(--space-2);
    width: 128px;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--space-2);

}

.blog-item {
    text-align: center;
}

.blog-image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}

.blog-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    /* left: 30px; */
    top: 30px;
}

.show {
    display: block;
}

.language {
    cursor: pointer;
}

.language:hover {
    background-color: #ddd;
}

.addresses {
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
}

.collapsible {
    width: 382px;
    height: 100%;
    cursor: pointer;
    transition: 3s;
}

.contact-card {
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
}

.contact-inform {
    align-items: center;
    display: flex;
}

.collapsible .contact-card .contact-image {
    width: 140px;
    height: 90px;
    background-color: var(--white-color);
}

.contact-image img {
    width: 100%;
    height: 100%;
}

.collapsible .contact-content {
    overflow: auto;
    max-height: 754px;
    animation: growDown 700ms ease-in-out;
    transform-origin: top center;
    border-right: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    padding-top: 32px !important;
    padding-left: 16px;
    padding-right: 16px;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }

    80% {
        transform: scaleY(1.1)
    }

    100% {
        transform: scaleY(1)
    }
}

.collapsible .seperator {
    width: 100%;
    height: 2px;
    background: #fff;
}

.icon-arrow {
    position: absolute;
    top: 16px;
    right: 16px;
    transition: transform 0.3s ease-in-out
}

.icon-arrow.rotate {
    transform: rotateX(180deg);
}

.shop-container {
    background-color: #fff;
    margin-bottom: 24px;
}

.shop-container img {
    width: 100%;
    height: 100%;
}

.contact-phone {
    align-items: center;
    display: flex;
    font-size: 14px;
    line-height: 16px;
    color: #01091C;
    padding-bottom: 12px
}

.contact-phone svg {
    margin-right: 5px;
}

.bg-light {
    background-color: #fff;
}

.pointer {
    cursor: pointer;
}


section {
    padding-top: 15px;
    padding-bottom: 30px;
}

.price-infos {
    display: flex;
    /* flex-direction: column;
    justify-content: center; */
}

.footer section {
    margin-right: 15px;
}

.item-card:hover {
    box-shadow: 0 11px 6px -6px rgba(0, 0, 0, 0.2);
}
.item-card:hover img {
    transform: scale(1.1);
    filter: brightness(90%);
}

.item-card:hover>.bottom-infos .price-infos .button-secondary-outline {
    background-color: var(--primary-color);
    width: 96px;
    color: white;
}

.item-card .extra-infos {
    position: absolute;
    top: var(--space-2);
    left: var(--space-2);
}



.item-card img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensures the image covers the card while maintaining its aspect ratio */
    transition: transform 0.3s ease;
}

.item-card .small-infos {
    padding: 0 16px;
}

.item-card .price-infos {
    display: flex;
    padding: var(--space-1) 16px;
    justify-content: space-between;
    align-items: center;
}

.base-button {
    font-family: 'TT_FORS_TRIAL_MEDIUM', sans-serif;
    font-weight: 500;
    font-size: 13px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    width: 255px;
    cursor: pointer;

}

.button-primary {
    background-color: #0052D2;
    color: var(--light-color);
}

.button-secondary {
    background-color: #D5D5D5;
    color: black;
}

.button-secondary-outline {
    background-color: rgba(41, 72, 141, .15);
    color: var(--primary-color);
    font-size: 13px;
    font-family: 'TT_FORS_TRIAL_MEDIUM', sans-serif;
    font-weight: 500;
    width: 96px;
}

.button-primary-outline {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    transition: .37s;
    font-size: 13px;
    font-family: 'TT_FORS_TRIAL_MEDIUM', sans-serif;
    font-weight: 500;
}

.button-primary-outline:hover {
    background-color: var(--primary-color);
    color: white;

}

.mobile-appear,
.mobile-appear * {
    display: none;
}

.icon {
    font-size: 16px;
    width: 18px;
}

.open-content .icon {
    margin-right: 15px;
}
.filter-text {
    color: #787878;
    height: 38px;
    padding: 8px;
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
}